import { Component, OnInit } from '@angular/core';
import { DataService } from '../../data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-bill',
  templateUrl: './bill.component.html',
  styleUrls: ['./bill.component.scss']
})
export class BillComponent implements OnInit {

bill:any = {
				status:0, patient:"", patient_id:0, type:{id:0, name:""},
				bill_no:"",
				bill_no_prefix:"",
				suggested_discount:{
					amount:"",
					doctor:{
						id:0,
						name:""
					},
					discount_approved:0
				},
				discount_preset:{
					id:0,
					name:"",
					percentage:0
				}
			};
id:any = '0';
therapists:any = [];
selectedTherapist:any = 0;	
branch:any = {id:0, name:""};
logo:any = null;
address:any = "";
selectedPaymentMode:any = "";
selectedReturnItem:any = {};
billStatuses:any = [
	{id:0, name:"Chargeable"},
	{id:1, name:"Complementary"},
	{id:2, name:"VSSC"}
];
walletBalance:any = 0;

pharmaLabels:any = [
    "",
	"Normal",
	"Courier",
	"Home Delivery"
];

pharmacyLabels:any = [
    {
      id:1,
      label:"Normal"
    },
    {
      id:2,
      label:"Courier"
    },
    {
      id:3,
      label:"Home Delivery"
    },
  ];
discountPresets:any = [];
selectedDiscountPreset:any = 0;
labels:any = [];

  constructor(private router:Router, private route: ActivatedRoute, private dataService: DataService, private location: Location) { }

  ngOnInit() {

	this.id = (this.route.snapshot.paramMap.get('id') != null) ? this.route.snapshot.paramMap.get('id') : 0;
	this.branch = (localStorage.getItem("branch") != null) ? JSON.parse(localStorage.getItem("branch")) : {id:0, name:""}; 

  	this.loadBill();
	this.loadTherapists();
	this.loadLogo();
	this.loadDiscountPresets();
	this.loadBillLabels();
  }

  loadBill(){

  	this.dataService.getSingleBill(this.id).subscribe( (data: any )=> {
		this.bill = data.data;
		console.log("bill", this.bill.allow_edit_date);
		this.loadWalletBalance();
	});

  }

  loadBillLabels(){
    this.dataService.getBillLabels().subscribe( (data: any  )=> {
      this.labels = data
    });
  }

  loadDiscountPresets(){
	this.dataService.getDiscountPresets().subscribe( (data: any )=> {
		this.discountPresets = data;
		
	});
  }

  loadWalletBalance(){
	this.dataService.getClientWalletBalance(this.bill.patient_id).subscribe( (data: any )=> {
		if(data.status == 0){
			this.walletBalance = data.balance;
		}
		
	});
  }

  loadTherapists(){
    this.dataService.getTherapists(this.branch.id).subscribe( (data: any = {status:0} )=> {
      if(data.status == 0){
        this.therapists = data;
      }
      
      
    });
  }

loadLogo(){
	this.dataService.getLogoData().subscribe( (data: any = {status:0, logo_data:""} )=> {
		if(data.status == 0){
			this.logo = data.logo_data;
			this.address = data.address;
		}
		
		
	});
}

submitCons(data){
	data.bill_id = this.id;
	data.field = "consultant_id";
	this.dataService.postUpdateBill(data).subscribe( (res:any) => {
		if(res.status == 0) {
		$("#modal-edit-cons").modal('hide');
		$(".btn-close-modal").trigger("click");
		  this.loadBill();
		} else {
		  alert(res.msg);
		}

	  }); 

}

submitLabel(data){
	data.bill_id = this.id;
	data.field = "label_id";
	this.dataService.postUpdateBill(data).subscribe( (res:any) => {
		if(res.status == 0) {
		$("#modal-edit-label").modal('hide');
		$(".btn-close-modal").trigger("click");
		  this.loadBill();
		} else {
		  alert(res.msg);
		}

	  }); 

}


submitPharmaLabel(data){
	data.bill_id = this.id;
	data.field = "pharma_label_id";
	this.dataService.postUpdateBill(data).subscribe( (res:any) => {
		if(res.status == 0) {
		$("#modal-edit-pharmalabel").modal('hide');
		$(".btn-close-modal").trigger("click");
		  this.loadBill();
		} else {
		  alert(res.msg);
		}

	  }); 

}

submitPm(data){
	
	
	if(data.mode != 0){
		data.bill_id = this.bill.id;
		this.dataService.postMarkBillPaid(data).subscribe( (res:any) => {
		if(res.status == 0) {
			  alert(res.msg);
			
			this.loadBill();
			$("#modal-edit-pm").modal('hide');
			$(".btn-close-modal").trigger("click");
		} else {
		  alert(res.msg);
		}
		} );
	} else {
		alert("Select a mode of payment to continue.");
	}
	
  }

submitDiscount(data){
	if(!isNaN(data.discount)){

		data.bill_id = this.bill.id;
		this.dataService.postUpdateBillDiscount(data).subscribe( (res:any) => {
		if(res.status == 0) {
			alert(res.msg);
			this.loadBill();
			$("#modal-edit-discount").modal('hide');
			$(".btn-close-modal").trigger("click");
		} else {
		  alert(res.msg);
		}
		} );

	} else {
		alert("Discount must be a number");
	}
}

submitSuggestedDiscount(data){
	if(!isNaN(data.discount)){

		data.bill_id = this.bill.id;
		this.dataService.postUpdateBillSuggestedDiscount(data).subscribe( (res:any) => {
		if(res.status == 0) {
			alert(res.msg);
			this.loadBill();
			$("#modal-edit-discount").modal('hide');
			$(".btn-close-modal").trigger("click");
		} else {
		  alert(res.msg);
		}
		} );

	} else {
		alert("Discount must be a number");
	}
}

cancelDiscountSuggestion(){
	var cnf = confirm("Do you really want to cancel this discount suggestion?");
	if(cnf){
		let data = {
			bill_id: this.bill.id,
			discount:0
		}

		this.dataService.postUpdateBillSuggestedDiscount(data).subscribe( (res:any) => {
			if(res.status == 0) {
				alert("Discount suggestion cancelled");
				this.loadBill();
				$("#modal-edit-discount").modal('hide');
				$(".btn-close-modal").trigger("click");
			} else {
			  alert(res.msg);
			}
			} );
	}
}

ignoreDiscountSuggestion(){
	var cnf = confirm("Do you really want to ignore this discount suggestion?");
	if(cnf){
		let data = {
			bill_id: this.bill.id,
			
		}

		this.dataService.postBillIgnoreSuggestedDiscount(data).subscribe( (res:any) => {
			if(res.status == 0) {
				alert(res.msg);
				this.loadBill();
				$("#modal-edit-discount").modal('hide');
				$(".btn-close-modal").trigger("click");
			} else {
			  alert(res.msg);
			}
			} );
	}
}

applyDiscountSuggestion(){
	var cnf = confirm("Do you really want to apply this discount suggestion?");
	if(cnf){
		let data = {
			bill_id: this.bill.id,
		}

		this.dataService.postBillApplySuggestedDiscount(data).subscribe( (res:any) => {
			if(res.status == 0) {
				alert(res.msg);
				this.loadBill();
				$("#modal-edit-discount").modal('hide');
				$(".btn-close-modal").trigger("click");
			} else {
			  alert(res.msg);
			}
			} );
	}
}


selectReturnItem(item){
	this.selectedReturnItem = item;
	
}

submitReturn(input){
	console.log(input);
	let allowed = this.selectedReturnItem.qty - this.selectedReturnItem.returned;
	if(input.current_return > allowed){
		
		alert("You can't return more than " + allowed + " quantity of this item" );
	} else {
		let data = {
			bill_id:this.bill.id,
			bc_id:this.selectedReturnItem.id,
			stock_id:this.selectedReturnItem.stock_id,
			return_qty:input.current_return,
			rate:this.selectedReturnItem.rate
		}

		this.dataService.postPatientReturnStock(data).subscribe( (res:any) => {
			if(res.status == 0) {
				alert(res.msg);
				this.loadBill();
				$(".btn-close-modal").trigger("click");
			} else {
			  alert(res.msg);
			}
			} );

		
	}
}


  /*print */

  printTbcBill(){
	//@ts-ignore
	var doc = new jsPDF();
	var y = 10;
	var yr = 30;

	//Logo
	doc.addImage(this.logo, 'PNG', 30,0,56,22); 
	y+=30; yr+=20;
	doc.setFontSize(10);
	doc.setFontType('bold');
	doc.text(100, y, 'Tax Invoice');
	y+=10;

	//from
	doc.setFontSize(8);
	doc.text(10,y, "Talking Brains Center FZE");
	y+=5;
	doc.setFontType('normal');
	doc.text(10,y, "Office 505, 5/F, Apricot Tower,");
	y+=5;
	doc.text(10,y, "Silicon Oasis,");
	y+=5;
	doc.text(10,y, "Dubai, UAE.");
	y+=5;
	doc.text(10,y, "Emirate: Dubai");
	y+=5;
	doc.text(10,y, "TRN: 100332404100003");

	//buyer
	y+=10;
	doc.text(10, y, "Paid by");
	y+=5;
	doc.setFontType('bold');
	doc.text(10, y, this.bill.patient);
	doc.setFontType('normal');
	y+=5;
	doc.text(10,y, this.bill.phone);
	y+=5;
	doc.text(10,y, "Emirate:");
	doc.text(35, y, this.bill.city);
	y+=5;
	doc.text(10,y, "Country:");
	doc.text(35,y, this.bill.country);
	y+=5;
	doc.text(10,y, "Place of Supply:");
	doc.text(35,y, "Dubai, UAE");

	//right side of header
	doc.setFontType('bold');
	doc.text(135, yr, "Invoice No.");
	doc.text(169, yr, "Dated");
	doc.setFontType('normal');
	yr+=5;
	doc.text(135, yr, this.bill.bill_no.toString());
	doc.text(169, yr, this.bill.bill_date);
	
	yr+=10;
	doc.setFontType('bold');
	doc.text(135, yr, "Therapist");
	doc.text(169, yr, "Mode of Payment");
	doc.setFontType('normal');
	yr+=5;
	doc.text(135,yr, this.bill.consultant);
	doc.text(169, yr, this.bill.payment_mode);

	//particulars

	y+=10;
	doc.setFontType('bold');
	doc.text(10,y, "Sl No.");
	doc.text(26, y, "Particulars");
	doc.text(121, y, "Quantity", 'right');
	doc.text(140, y, "Rate", 'right');
	doc.text(159, y, "Amount", 'right');
	doc.text(185, y, "VAT %", 'right');

	doc.setFontType('normal');
	y+=7;
	var counter = 1;
	this.bill.contents.forEach(element => {
		doc.text(10,y, counter.toString());
		doc.text(26, y, element.description);
		doc.text(121, y, element.qty.toString(), 'right' );
		doc.text(140, y, element.rate.toString(), 'right');
		doc.text(159, y, element.amount.toString(), 'right');
		doc.text(185, y, "0%", 'right');
		
		if(this.bill.type == 2){
			doc.text(26, y+5, "Batch: " + element.batch + " Expiry: " + element.expiry );
			
			y += 10;
		} else {
			y+=5;
		}
		
		
		counter++;
	});

	
	
	
	

	//Total;

	doc.setFontType('bold');
	y+=20;
	doc.text(121, y, "Total", 'right');
	doc.setFontSize(10);
	doc.text(159, y, "AED "+this.bill.total.toString(), 'right');

	doc.setFontType('normal');
	doc.setFontSize(8);

	y+=10;
	doc.text(10, y, "Amount chargeable (in words)");
	y+=5;
	doc.setFontSize(9);
	doc.setFontType('bold');
	//@ts-ignore
	doc.text(10, y, "UAE Dirham "+number2words(this.bill.total)+" Only (AED " +this.bill.total.toString() + ")"  );
	
	


	//output
	var string = doc.output('datauristring');
	var newWindow = window.open("", "PDF", 'dependent=yes,locationbar=no,scrollbars=no,menubar=no,resizable,screenX=50,screenY=50,width=850,height=800');
	newWindow.document.write(
	'<html><body><center>' +
	
	'</center><br>' +
	'<object width=100% height=100% type="application/pdf" data="'+string+'">' +
	'<embed type="application/pdf" src="'+string+'" id="embed_pdf"></embed>' +
	'</object></body></html>');
	newWindow.window.focus();

  }

  printTbcReceipt(){
	//@ts-ignore
	var doc = new jsPDF("l", "mm", [148,210]);
	var y = 10;
	var yr = 30;

	//Logo
	doc.addImage(this.logo, 'PNG', -20,0,155,37); // logo dimensions: 210x50mm

	doc.setFontSize(12);
	doc.setFontStyle('italic');
	y += 2;
	doc.text("Office 505, Appricot Tower, Silicon Oasis, Dubai", 195, y, 'right');
	y +=6;
	doc.text("P.O Box: 393984, 04 320 9307 / 055 896 7482", 195, y, 'right');
	y +=6;
	doc.text("www.talkingbrainscenter.com", 195, y, 'right');
	y +=6;
	doc.text("TRN: 100332404100003", 195, y, 'right');

	y += 15;
	doc.setFontStyle('normal');

	doc.text("Date: " + this.bill.bill_date, 10, y);
	doc.text("Receipt No.: " + this.bill.bill_no.toString(), 195, y, 'right');

	y += 25;
	var pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
	var ParaWidth=pageWidth*0.95; /* para width will 85% of the page width. */

	let receiptBody = "We have received from Mr./Mrs. "+this.bill.payer_name+" for his/her son/daughter/himself "+this.bill.patient+" an amount of AED "+this.bill.payable+" for the following service/s provided in the center:";

	
	
	var lines = doc.splitTextToSize(receiptBody, ParaWidth);
	doc.text(10, y , lines);
	console.log("lines", lines);

	y += 5*(lines.length + 1);

	//let description = "Description: Speech therapy (therapist: Reine Bou Issa; Duration: 60 minutes), Occupational therapy (therapist: Reine Bou Issa; Duration: 60 minutes)";

	let description = "Description: ";

	this.bill.contents.forEach(row => {
		description += row.description;
		if(row.therapist.length > 0 || row.duration.length > 0)	{
			description += " (";

			if(row.therapist.length > 0){
				description += "Therapist: " + row.therapist + "; ";
			}

			if(row.duration.length > 0){
				description += "Duration: " + row.duration;
			}

			description += ")";
		}
		description += ". ";
	});

	var lines = doc.splitTextToSize(description, ParaWidth);
	doc.text(10, y , lines);

	y += 5*(lines.length + 1);

	if(this.bill.is_paid == 1){
		doc.text("Payment made by: " + this.bill.payment_mode, 10, y);	
	}
	

	let yfooter = 135;

	doc.line(10, yfooter, 80, yfooter);
	doc.text("Payment received by", 20, yfooter+5);

	doc.line(120, yfooter, 200, yfooter);
	doc.text("Authorized signature", 140, yfooter+5);


	//output
	var string = doc.output('datauristring');
	var newWindow = window.open("", "PDF", 'dependent=yes,locationbar=no,scrollbars=no,menubar=no,resizable,screenX=50,screenY=50,width=850,height=800');
	newWindow.document.write(
	'<html><body><center>' +
	
	'</center><br>' +
	'<object width=100% height=100% type="application/pdf" data="'+string+'">' +
	'<embed type="application/pdf" src="'+string+'" id="embed_pdf"></embed>' +
	'</object></body></html>');
	newWindow.window.focus();

  }

  printBill(){
	//@ts-ignore
	//var doc = new jsPDF();
	var doc = new jsPDF('l', 'mm', [148, 210]);

	

	var y = 0;
	var yr = 20;
	
	//Logo
	/*if(this.logo != null){
		doc.addImage(this.logo, 'PNG', 80,5,56,22); // logo dimensions: 210x50mm
		y+=30; yr+=20;
	} */
	
	y+=35; yr+=15; //Top Margin;
	
	/*doc.setFontSize(10);
	doc.setFontType('bold');
	doc.text(100, y, 'Invoice');
	y+=10; */

	//from
	doc.setFontSize(8);
	

	//buyer
	//y+=10;
	doc.setFontType('bold');
	doc.text(10, y, "Patient");
	y+=5;
	doc.setFontType('normal');
	let patientName = "";
	if(this.bill.patient_id != 0){
		patientName = this.bill.patient + " ("+this.bill.reg_no+")"
	} else {
		patientName = this.bill.external_name;
	}
	doc.text(10, y, patientName);
	
	y+=5;
	let phoneRow = "";
	if(this.bill.patient_phone != null){
		phoneRow += "Phone: " + this.bill.patient_phone;
	}

	if(this.bill.patient_age != null){
		phoneRow += " Age: " + this.bill.patient_age;
	}

	if(phoneRow.length > 0){
		doc.text(10,y, phoneRow);
		y+=5;
	}

	
	let billNo = "";

	if(this.bill.bill_no_prefix.length > 0){
		billNo += this.bill.bill_no_prefix+"/";
	}

	billNo += this.bill.bill_no.toString();

	
	
	
	//right side of header
	doc.setFontType('bold');
	doc.text(135, yr, "Invoice No.");
	doc.text(169, yr, "Date");
	doc.setFontType('normal');
	yr+=5;
	doc.text(135, yr, billNo);
	doc.text(169, yr, this.bill.bill_date);
	
	yr+=10;
	doc.setFontType('bold');
	doc.text(135, yr, "Consultant");
	doc.text(169, yr, "Mode of Payment");
	doc.setFontType('normal');
	yr+=5;
	doc.text(135,yr, this.bill.consultant);
	doc.text(169, yr, this.bill.payment_mode);

	//particulars
	y+=10;

	y+=10;
	doc.setFontType('bold');
	doc.text(10,y, "Sl No.");
	doc.text(26, y, "Particulars");
	if(this.dataService.localData.gstBilling == 1 && (this.bill.type.id == 2 || this.bill.type.id == 7 || this.bill.type.id == 8 || this.bill.type.id == 6)){
		doc.text(135, y, "HSN/SAC", 'right');
	}
	
	doc.text(151, y, "Quantity", 'right');
	doc.text(170, y, "Rate", 'right');
	doc.text(189, y, "Amount", 'right');
	

	doc.setFontType('normal');
	y+=7;
	var counter = 1;
	this.bill.contents.forEach(element => {
		doc.text(10,y, counter.toString());
		doc.text(26, y, element.description);
		if(this.dataService.localData.gstBilling == 1 && (this.bill.type.id == 2 || this.bill.type.id == 7 || this.bill.type.id == 8 || this.bill.type.id == 6)){
			doc.text(135, y,  element.hsn_code.toString(), 'right');
		}
		doc.text(151, y, element.qty.toString(), 'right' );
		doc.text(170, y, element.rate.toString(), 'right');
		doc.text(189, y, element.amount.toString(), 'right');
		
		if(this.bill.type == 2){
			console.log("pharma bill");
			doc.text(26, y+5, "Batch: " + element.batch + " Expiry: " + element.expiry );
			
			y += 10;
		} else {
			y+=5;
		}
		counter++;
		if(y >= 130){
			doc.addPage();
			y = 30;
		}
	});

	
	
	
	

	//Total;

	doc.setFontType('bold');
	y+=15;
	let yl = y;
	doc.text(151, y, "Total", 'right');
	doc.setFontSize(10);
	doc.text(189, y, this.bill.short_c +" "+this.bill.total.toString(), 'right');

	if(y >= 130){
		doc.addPage();
		y = 30;
	}

	if( Number(this.bill.discount) != 0){

		doc.setFontSize(8);
		y+=7;
		doc.text(151, y, "Discount", 'right');
		doc.setFontSize(10);
		doc.text(189, y, this.bill.short_c +" "+this.bill.discount.toString(), 'right');
		if(y >= 130){
			doc.addPage();
			y = 30;
		}
		doc.setFontSize(8);
		y+=7;
		doc.text(151, y, "Payable", 'right');
		doc.setFontSize(10);
		doc.text(189, y, this.bill.short_c +" "+this.bill.payable.toString(), 'right');
		if(y >= 130){
			doc.addPage();
			y = 30;
		}
	}

	if(this.dataService.localData.gstBilling == 1 && (this.bill.type.id == 2 || this.bill.type.id == 7 || this.bill.type.id == 8 || this.bill.type.id == 6)){
		doc.setFontSize(8);
		y+=7; 
		doc.text(151, y, "CGST", 'right');
		doc.text(189, y, this.bill.short_c +" "+this.bill.cgst.toString(), 'right');
		y+=5;
		doc.text(151, y, "SGST", 'right');
		doc.text(189, y, this.bill.short_c +" "+this.bill.sgst.toString(), 'right');
	}

	



	doc.setFontType('normal');
	doc.setFontSize(8);

	//y+=10;
	doc.text(10, yl, "Amount payable (in words)");
	yl+=5;
	doc.setFontSize(9);
	doc.setFontType('bold');

	//@ts-ignore
	let amountWords = this.bill.long_c+" "+number2words(this.bill.payable)+" Only ("+this.bill.short_c+ " " + this.bill.payable.toString() + ")"; 
	
	var amountWordsSplit = doc.splitTextToSize(amountWords, 120);
	doc.text(10, yl, amountWordsSplit);
	
	


	//output
	var string = doc.output('datauristring');
	var newWindow = window.open("", "PDF", 'dependent=yes,locationbar=no,scrollbars=no,menubar=no,resizable,screenX=50,screenY=50,width=850,height=800');
	newWindow.document.write(
	'<html><body><center>' +
	
	'</center><br>' +
	'<object width=100% height=100% type="application/pdf" data="'+string+'">' +
	'<embed type="application/pdf" src="'+string+'" id="embed_pdf"></embed>' +
	'</object></body></html>');
	newWindow.window.focus();

  }


  submitBillCancel(data) {
	//@ts-ignore
	data.bill_id = this.id;
	this.bill.is_cancelled = 1;
	this.dataService.postCancelBill(data).subscribe( (res:any) => {

		if(res.status == 0) {
		alert(res.msg);
		$('#modal-cancel-bill').modal('hide');
		$(".btn-close-modal").trigger("click");
		
		this.loadBill();
		} else {
		alert(res.msg);
		}

	} ); 


 }

 updatePayer(){
	let input = prompt("Enter payer name", this.bill.payer_name);

	if(input){
		let data = {patient_id:this.bill.patient_id, payer_name:input}
		this.dataService.postUpdatePayerName(data).subscribe( (res:any) => {

			if(res.status == 0) {
				this.bill.payer_name = input;
			} else {
				alert(res.msg);
			}
	
		} ); 
	}

 }

 submitBillComplementaryStatus(data){
	data.bill_id = this.bill.id;
	this.dataService.postBillComplementaryStatus(data).subscribe( (res:any) => {

		if(res.status == 0) {
			this.bill.complementary_status = data.billStatus;
			$(".btn-close-modal").trigger("click");
		} else {
			alert(res.msg);
		}

	} ); 
 }

 submitBillDate(data){
	data.bill_id = this.bill.id;
	this.dataService.postUpdateBillDate(data).subscribe( (res:any) => {

		if(res.status == 0) {
			this.bill.bill_date = res.bill_date;
			this.bill.bill_date_ = res.bill_date_;
			$(".btn-close-modal").trigger("click");
		} else {
			alert(res.msg);
		}

	} ); 
 }

 submitPresetDiscount(){
	
	let data = {
		bill_id:this.id,
		preset_id:this.selectedDiscountPreset
	}

	this.dataService.postBillPresetDiscount(data).subscribe( (res:any) => {

		if(res.status == 0) {
			this.loadBill();
			$(".btn-close-modal").trigger("click");
		} else {
			alert(res.msg);
		}

	} ); 
 }

 editExtName(){
    let input = prompt("External Patient Name", this.bill.external_name);

    if(input){

      let data = {id:this.id, name: input}

      this.dataService.postEditBillExtName(data).subscribe( (res:any) => {
        
        if(res.status == 0) {
          //this.loadBill();
		  this.bill.external_name = input;
          
        } else {
          alert(res.msg);
        } 
  
  
      });
    }

  }

  editExtPhone(){
    let input = prompt("External Patient Phone", this.bill.external_phone);

    if(input){

      let data = {id:this.id, phone: input}

      this.dataService.postEditBillExtPhone(data).subscribe( (res:any) => {
        
        if(res.status == 0) {
          //this.loadBill();
		  this.bill.external_phone = input;
          
        } else {
          alert(res.msg);
        } 
  
  
      });
    }

  }

  reCalculate(){
	var cnf = confirm("Confirm this action");

	if(cnf){
		let data = {id:this.id}

		this.dataService.postRecalculatePharmaBillTotal(data).subscribe( (res:any) => {
        
			if(res.status == 0) {
			  this.loadBill();
			  
			  
			} else {
			  alert(res.msg);
			} 
	  
	  
		  });

	}
  }

  editRate(row){
	let input = prompt("Update amount", row.rate);

	if(input){
		let data = {
			rowId:row.id,
			rate:input
		}
		this.dataService.postUpdateBCAmount(data).subscribe( (res:any) => {
        
			if(res.status == 0) {
			  this.loadBill();
			  
			  
			} else {
			  alert(res.msg);
			} 
	  
	  
		  });
	}

	

  }


  /*

  printBill() {
	var bill = this.bill;
	var imgData = this.logo;
	
	//@ts-ignore
	var doc = new jsPDF();
	
	doc.addImage(imgData, 'PNG', 7,2,40,10);
	doc.setFontSize(8);
	doc.text(200, 6, this.address, 'right');
	//doc.text(200, 10, "Phone: " + bill.phone, 'right');

	doc.text(10, 35, "Patient: " + bill.patient_name + " (" + bill.patient_id + ")"  );
	doc.text(10, 40, "Bill No.: " + bill.bill_no );
	doc.text(10, 45, "Bill Date: " + bill.bill_date );

	doc.setFontType('bold');

	doc.text(10, 55, 'Sl No.');
	doc.text(20, 55, 'Particulars');
	doc.text(100, 55, 'Rate', 'right');
	doc.text(120, 55, 'Quantity', 'right');
	doc.text(140, 55, 'HSN', 'right');
	doc.text(160, 55, 'GST Rate', 'right');
	doc.text(180, 55, 'GST Amt', 'right');
	doc.text(200, 55, 'Amount', 'right');
	doc.setFontType('normal');
	var i = 1;
	var y = 60;

	$.each(bill.bill_data, function(key, index){
		doc.text(10, y, i.toString());
		doc.text(20, y, this.item);
		doc.text(100, y, this.rate, 'right');
		doc.text(120, y, this.qty.toString(), 'right');
		doc.text(140, y, this.hsn_code, 'right');
		doc.text(160, y, this.gst_rate.toString(), 'right');
		doc.text(180, y, this.gst_amount, 'right');
		doc.text(200, y, this.amount, 'right');
		i++;
		y += 5;
	});

	//print total

	doc.setFontType('bold');
	doc.text(184, y+5, "CGST: " + bill.cgst);
	doc.text(184, y+10, "SGST: " + bill.sgst);
	doc.text(172, y+15, "Total Amount: " + bill.total);


	//output
	var string = doc.output('datauristring');
	var newWindow = window.open("", "PDF", 'dependent=yes,locationbar=no,scrollbars=no,menubar=no,resizable,screenX=50,screenY=50,width=850,height=800');
	newWindow.document.write(
	'<html><body><center>' +
	
	'</center><br>' +
	'<object width=100% height=100% type="application/pdf" data="'+string+'">' +
	'<embed type="application/pdf" src="'+string+'" id="embed_pdf"></embed>' +
	'</object></body></html>');
	newWindow.window.focus();

} */



}
